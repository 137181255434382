<template>
  <div v-if="$userInfo">
    <print-preview-with-side-view
      :emailer="emailer"
      :report-name="reportName"
      :parameters="parameters"
      :parameter-id="parameterId">
      <template v-slot:side-bar>
        <debtor-aging-report-side-menu @ok="okButton" />
      </template>
    </print-preview-with-side-view>
  </div>
</template>

<script>
import { AppHeaderButtonTypes } from '@/enums'
import { Emailer } from '@/classes'
import { PrintPreviewWithSideView } from '@/components/printpreview'
import DebtorAgingReportSideMenu from './DebtorAgingReportSideMenu'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DebtorAgingReportView',
  components: {
    PrintPreviewWithSideView,
    [DebtorAgingReportSideMenu.name]: DebtorAgingReportSideMenu
  },
  data() {
    return {
      reportName: '',
      reportTitle: '',
      query: '',
      reportType: '',
      emailer: new Emailer(),
      parameters: new KeyValuePairModel(),
      parameterId: ''
    }
  },
  created() {
    this.initialReport()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    initialReport() {
      this.reportName = 'AT_ARDetailedAgingReportV2'
      this.reportTitle = 'Accounts Receivable Detailed Aging Report'
      this.emailer.subject = 'A/R Aging Detailed Report'
      this.emailer.reportName = 'ARDetailedAgingReport'
      let params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: this.reportTitle,
        COMPANYID: this.$userInfo.companyId
      }

      this.parameters = KeyValuePairModel.convertToKeyValuePairs(params)
      this.parameterId = this.$guid.newGuid()
    },
    okButton(reportType) {
      if (reportType === 'detailed') {
        this.reportName = 'AT_ARDetailedAgingReportV2'
        this.reportTitle = 'Accounts Receivable Detailed Aging Report'
        this.emailer.subject = 'A/R Aging Detailed Report'
        this.emailer.reportName = 'ARDetailedAgingReport'
      } else if (reportType === 'summary') {
        this.reportName = 'AT_ARSummaryAgingReportV2'
        this.reportTitle = 'Accounts Receivable Summary Aging Report'
        this.emailer.subject = 'A/R Aging Summary Report'
        this.emailer.reportName = 'ARSummaryAgingReport'
      }
      let params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: this.reportTitle,
        COMPANYID: this.$userInfo.companyId
      }

      this.parameters = KeyValuePairModel.convertToKeyValuePairs(params)
      this.parameterId = this.$guid.newGuid()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
